import React from 'react';

import "./Footer.css";

const Footer = () => {

    return (
        <div className="footer-row">
            <p className="footer-text">© D-Star 2024</p>
        </div>
    )

}

export default Footer;