import React from 'react';
import Header from '../components/Header';
import Footer from '../components/Footer';

import Arrow from "../assets/arrow_right.png";
import ArrowBoth from "../assets/arrow_both.png";

import "./Home.css";

const Home = () => {

    const features = [
        {
            header: "State-of-the-art accuracy",
            text: "D-Star minimizes the risk of hallucinations and misleading responses in chat and generation applications by providing accurate and complete retrieval results."
        },
        {
            header: "Private cloud deployment",
            text: "Maintain control over your data by deploying D-Star directly in your AWS, Azure, or GCP account. We can even deploy on-prem if needed."
        },
        {
            header: "Support",
            text: "We help you customize the retrieval configuration and components for your use case and make sure everything runs smoothly and performs well. We also provide ongoing support and regular updates as needed."
        }
    ]

    return (
        <div className="home-section">

            <Header lightOrDark="dark" />

            <div className="home-container">

                <div className="homepage-main-content-container">
                    <p className="homepage-main-text">
                        D-Star is a high-performance retrieval engine that achieves state-of-the-art accuracy on challenging RAG benchmarks.
                    </p>

                    <div className="homepage-graphic-row">
                        <div>
                            <p className="homepage-graphic-text">Your data</p>
                        </div>
                        <div>
                            <img src={Arrow} className="homepage-graphic-arrow" id="arrow-right" />
                        </div>
                        <div className="homepage-graphic-d-star-wrapper">
                            <p className="homepage-graphic-d-star-text">D*</p>
                        </div>
                        <div>
                            <img src={ArrowBoth} className="homepage-graphic-arrow" id="arrow-both" />
                        </div>
                        <div>
                            <p className="homepage-graphic-text">LLM application</p>
                        </div>
                    </div>

                </div>

                {features.map((feature, index) => {
                    return (
                        <div className="feature-row" key={index}>
                            <p className="feature-header">{feature.header}</p>
                            <p className="feature-text">{feature.text}</p>
                        </div>
                    )
                })}

                <div className="contact-button-container" onClick={() => window.open("https://docs.google.com/forms/d/e/1FAIpQLScDFTW7ADv2rnyGEMYTW8ACB79Rz_nVCAV_tYF5M8NkSzp38g/viewform", "_blank")}>
                    <p className="contact-button-text">Contact us</p>
                </div>

            </div>

            <Footer />
        </div>
    )

}

export default Home;

/*
<p className="main-content-text">
                        Open-source <b>data warehouse</b> for unstructured data
                    </p>
                    <p className="main-content-sub-text">
                        D* creates a seamless connection between data sources and LLMs, enabling users to query, analyze, and run reports over unstructured data.
                    </p>
                    <div className="learn-more-button-container" onClick={() => navigate("/blogs")}>
                        <p className="learn-more-text">
                            Learn more
                        </p>
                    </div>
                    */