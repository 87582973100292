import React from 'react';
import { useNavigate } from 'react-router-dom';
import { IconContext } from 'react-icons';
import { GiHamburgerMenu } from "react-icons/gi";

import "./Header.css";

const Header = ({ lightOrDark = "light" }) => {

    const navigate = useNavigate();

    return (
        <div className="home-header-row">
            <div>
                <p className={`d-star-logo-${lightOrDark}`} onClick={() => navigate("/")}>D*</p>
            </div>
            <div className="header-links-col">
                <a className="github-link-container" href="https://github.com/D-Star-AI/dsRAG">
                    <img id="github-image" src="https://img.shields.io/github/stars/D-Star-AI/dsRAG.svg?style=social&label=Star&maxAge=2400" />
                </a>
                <p className={`header-link-text-${lightOrDark}`} onClick={() => navigate("/blogs")}>Blog</p>
                <p className={`header-link-text-${lightOrDark}`} onClick={() => navigate("/pricing")}>Pricing</p>
                <p className={`header-link-text-${lightOrDark}`} onClick={() => window.open("https://docs.google.com/forms/d/e/1FAIpQLScDFTW7ADv2rnyGEMYTW8ACB79Rz_nVCAV_tYF5M8NkSzp38g/viewform", "_blank")}>Contact</p>
            </div>

            <div className="hamburger-menu-container">
                <div>
                    <IconContext.Provider
                        value={{ className: "hamburger-menu-icon" }}>
                        <div>
                            <GiHamburgerMenu />
                        </div>
                    </IconContext.Provider>
                </div>
            </div>
        </div>
    )

}

export default Header;